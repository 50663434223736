import { useMemo } from "react";
import { useTranslations } from "next-intl";

export const useDict = (namespace?: string) => {
  const t = useTranslations(namespace);
  const tc = useTranslations("common");

  return useMemo(() => {
    return { t, tc };
  }, [t, tc]);
};

export const useCommonDict = () => {
  const t = useTranslations("common");

  return useMemo(() => {
    return { t };
  }, [t]);
};

export const useDetailsDict = () => {
  const t = useTranslations("details");
  const { t: tc } = useDict("common");

  return useMemo(() => {
    return { t, tc };
  }, [t, tc]);
};

export const useBookingsDict = () => {
  const t = useTranslations("bookings");
  const { t: tc } = useDict("common");

  return useMemo(() => {
    return { t, tc };
  }, [t, tc]);
};

export const useOnboardingDict = () => {
  const t = useTranslations("onboarding");
  const { t: tc } = useDict("common");

  return useMemo(() => {
    return { t, tc };
  }, [t, tc]);
};

export const useSearchDict = () => {
  const t = useTranslations("search");
  const { t: tc } = useDict("common");

  return useMemo(() => {
    return { t, tc };
  }, [t, tc]);
};

export const useCommunityDict = () => {
  const t = useTranslations("community");
  const { t: tc } = useDict("common");

  return useMemo(() => {
    return { t, tc };
  }, [t, tc]);
};

export const useSpendingLimitsDict = () => {
  const t = useTranslations("spending-limits");
  const { t: tc } = useDict("common");

  return useMemo(() => {
    return { t, tc };
  }, [t, tc]);
};
