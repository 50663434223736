"use client";

import React, { ReactNode } from "react";
import {
  LogLevel,
  StatsigProvider,
  useClientBootstrapInit,
} from "@statsig/react-bindings";
import type { StatsigUser } from "statsig-node";

const SDK_KEY = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY;

interface FPStatsigProviderProps {
  children: ReactNode;
  bootstrapValues: {
    data: string;
    user: StatsigUser;
    key: string;
  };
}

export function FPStatsigProvider({
  children,
  bootstrapValues,
}: FPStatsigProviderProps) {
  if (!SDK_KEY) {
    console.error("Missing NEXT_PUBLIC_STATSIG_CLIENT_KEY");
    return <>{children}</>;
  }

  const client = useClientBootstrapInit(
    bootstrapValues.key,
    bootstrapValues.user,
    bootstrapValues.data,
    {
      logLevel:
        process.env.NODE_ENV === "development" ? LogLevel.Debug : LogLevel.None,
    },
  );

  return <StatsigProvider client={client}>{children}</StatsigProvider>;
}
