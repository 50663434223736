"use client";

import { useEffect, useState, useCallback } from "react";
import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";
import { useNavigator } from "../hooks/useNavigator";
import { useMe } from "~/hooks/useMe";

if (!process.env.NEXT_PUBLIC_CUSTOMER_IO_API_KEY) {
  console.warn("NEXT_PUBLIC_CUSTOMER_IO_API_KEY is not set");
}

if (!process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID) {
  console.warn("NEXT_PUBLIC_CUSTOMER_IO_SITE_ID is not set");
}

// Initialize Customer.io with EU data center
export const customerIO = AnalyticsBrowser.load(
  {
    writeKey: process.env.NEXT_PUBLIC_CUSTOMER_IO_API_KEY!,
    cdnURL: "https://cdp-eu.customer.io",
  },
  {
    integrations: {
      "Customer.io In-App Plugin": {
        siteId: process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID,
      },
    },
  },
);

export default function CustomerIO(): JSX.Element {
  // Initialize app bridge
  useEffect(() => {
    (window as any).eventBus = undefined;

    if ((window as any).webkit && (window as any).webkit.messageHandlers) {
      (window as any).eventBus = {
        callHandler: (handlerName: string, args: any) => {
          if (
            (window as any).webkit.messageHandlers.hasOwnProperty(handlerName)
          ) {
            (window as any).webkit.messageHandlers[handlerName].postMessage(
              args,
            );
          }
        },
      };
    } else if (
      (window as any).flutter_inappwebview &&
      (window as any).flutter_inappwebview.callHandler
    ) {
      (window as any).eventBus = {
        callHandler: (handlerName: string, args: any) => {
          (window as any).flutter_inappwebview.callHandler(handlerName, args);
        },
      };
    } else {
      (window as any).eventBus = undefined;
    }
  }, []);

  const [isCustomerioReady, setCustomerioReady] = useState<boolean>(false);
  const [isScriptReady, setScriptReady] = useState<boolean>(false);

  const { url } = useNavigator();
  const { me } = useMe();

  // Identify user
  useEffect(() => {
    if (!me || !process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID) {
      return;
    }

    customerIO.identify(me.id, {
      id: me.id,
      email: me.email,
      first_name: me.profile ? me.profile.firstName : undefined,
      last_name: me.profile ? me.profile.lastName : undefined,
    });
    setScriptReady(true);
  }, [me]);

  // Initialize native app bridge
  useEffect(() => {
    if (
      !isCustomerioReady &&
      (window as any).eventBus &&
      (window as any).eventBus.callHandler &&
      me &&
      me.id
    ) {
      (window as any).eventBus.callHandler("customerio", {
        id: me.id,
        siteId: process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID,
        apiKey: process.env.NEXT_PUBLIC_CUSTOMER_IO_API_KEY,
      });
      setCustomerioReady(true);
    }
  }, [me, isCustomerioReady]);

  // Track page views
  const onRouteChangeComplete = useCallback(
    (url: string) => {
      if (isScriptReady) {
        customerIO.page(url);
      }
    },
    [isScriptReady],
  );

  useEffect(() => {
    if (isScriptReady) {
      onRouteChangeComplete(url);
    }
  }, [url, isScriptReady, onRouteChangeComplete]);

  if (!me) {
    return null;
  }

  return null;
}
